














































import Vue from "vue";
import Component from "vue-class-component";
import Site from "../model/Site";
import { Actions } from "../store/actions";

@Component
export default class SiteSelector extends Vue {
  selection?: Site = undefined;

  get email(): string {
    return this.$store.state.core.user?.email;
  }

  get list(): Site[] {
    return this.$store.state.core.list;
  }

  itemText(item: Site): string {
    return `${item.customerId} ${item.name}`;
  }

  selectSite(selection: Site | null): void {
    this.$store.dispatch(`core/${Actions.SELECT_SITE}`, selection?.customerId || null, {
      root: true
    });
  }

  get site(): Site | null {
    return this.$store.state.core.site || undefined;
  }
}
