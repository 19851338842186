



















import { Actions } from "@/shared/core/store/actions";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { SnackType } from "../../core/events";
import { mediasService } from "../services/medias.service";

import Base from "../../core/components/Base.vue";

@Component
export default class MediaItemDelete extends Base {
  @Prop() mediaItemId!: string;

  deleteConfirmation = false;

  async deleteMediaItem() {
    if (!this.media) return;
    if (!this.site) return;
    const { customerId } = this.site;
    const mediaId = this.media?._id;

    this.startLoading();
    await mediasService
      .deleteMediaItem(mediaId, this.mediaItemId)
      .then(() => {
        this.snack("Document supprimé avec succès");
        this.$store.dispatch(`media/${Actions.SET_MEDIAS}`, customerId, { root: true });
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$emit("closeDialog");
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
