export const ORIGINS = {
  BIO: "Bio",
  LR: "Label Rouge",
  LRG: "La région du goût",
  NRC: "Nouvelle recette du chef",
  CNS: "Cuisiné par nos soins",
  PF: "Poisson frais",
  PRB: "Produit bio",
  PRE: "Produit eco",
  PRF: "Produit frais",
  PRL: "Produit local",
  PRV: "Produit vege",
  POF: "Porc français",
  VBF: "Viande bovine française",
  VLF: "Volaille française"
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  CHEESE: "FRO",
  DRINK: "BOI",
  SIDE: "ACC"
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  [PRODUCT_TYPES.SIDE]: "Accompagnement"
};
