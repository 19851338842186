


































































import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import Component from "vue-class-component";
import Base from "../../core/components/Base.vue";
import MediaFormDialog from "../components/MediaFormDialog.vue";
import { Media } from "../model/Media";

@Component({
  components: { MediaFormDialog }
})
export default class Medias extends Base {
  headers = [
    { text: "Nom", sortable: true, value: "name" },
    { text: "Nombre de médias", sortable: true, value: "items" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  get medias(): Media[] {
    return this.$store.getters[`media/${Getters.MEDIAS}`];
  }

  createMedia() {
    this.$store.commit(`media/${Mutations.SELECT_MEDIA_ID}`, null, { root: true });
    this.$store.commit(`media/${Mutations.FORM_DIALOG_MEDIA}`, true, { root: true });
  }

  editMedia(media: Media) {
    this.$store.commit(`media/${Mutations.SELECT_MEDIA_ID}`, media._id, { root: true });
    this.$store.commit(`media/${Mutations.FORM_DIALOG_MEDIA}`, true, { root: true });
  }
}
