









































































































































import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { ClosedDate } from "../model/ClosedDate";

@Component
export default class BookingConfigurationClosedDates extends Base {
  @Prop() closedDates!: ClosedDate[];

  today = new Date(Date.now()).toISOString();

  deleteConfirmation = false;

  showMenu = false;

  findClosedDate(closedDate: ClosedDate): number {
    return this.closedDates.findIndex(x => x.from === closedDate.from && x.to === closedDate.to);
  }

  formatBookingDate(bookingDate: Date) {
    return bookingDate.toLocaleString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    });
  }

  formatInputDate(date: Date | string) {
    if (date) {
      return `${this.formatBookingDate(new Date(date))}`;
    }
    return null;
  }

  setStartDate(evt: string, closedDate: ClosedDate) {
    this.closedDates[this.findClosedDate(closedDate)].from = evt;
  }

  setEndDate(evt: string, closedDate: ClosedDate) {
    this.closedDates[this.findClosedDate(closedDate)].to = evt;
  }

  startDateErrors(closedDate: ClosedDate) {
    if (
      this.closedDates.some(
        closedDateItem =>
          closedDateItem.from === closedDate.from && closedDateItem.to !== closedDate.to
      )
    )
      return "Une période de fermeture existe avec cette date de début";

    if (
      this.closedDates.some(
        closedDateItem =>
          closedDateItem.from < closedDate.from && closedDateItem.to > closedDate.from
      )
    )
      return "Une période de fermeture existe déjà dans cette plage";

    return "";
  }

  allClosedDatesValid() {
    return !this.closedDates.every(
      closedDate =>
        closedDate.from !== "" &&
        closedDate.to !== "" &&
        !this.closedDates.some(
          closedDateItem =>
            closedDateItem.from === closedDate.from && closedDateItem.to !== closedDate.to
        )
    );
  }
}
