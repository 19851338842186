

















































































































import { SnackType } from "@/shared/core/events";
import { Actions } from "@/shared/core/store/actions";
import { NotEmptyRule, ValidationRule } from "@/shared/core/validation/validation.rules";
import _ from "lodash";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Getters } from "../../core/store/getters";
import { Mutations } from "../../core/store/mutations";
import { Media } from "../model/Media";
import { MediaItem } from "../model/MediaItem";
import { mediasService } from "../services/medias.service";
import MediaDelete from "./MediaDelete.vue";
import MediaItemDialog from "./MediaItemDialog.vue";

@Component({
  components: {
    MediaDelete,
    MediaItemDialog
  }
})
export default class MediaFormDialog extends Base {
  mMedia: Partial<Media> = {};

  valid = false;

  mediaItemDialog = false;

  headers = [
    { text: "Nom", sortable: true, value: "name" },
    { text: "Début", sortable: false, value: "start" },
    { text: "Fin", sortable: false, value: "end" },
    { text: "Nombre d'images", sortable: true, value: "images" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  currentItem: MediaItem = {
    start: "",
    end: "",
    images: [],
    name: ""
  };

  @Watch("formDialogMedia")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      if (this.media) {
        this.mMedia = _.cloneDeep(this.media);
      } else {
        this.clear();
      }
    }
  }

  get edition() {
    return Boolean(this.mMedia._id);
  }

  set formDialogMedia(v: boolean) {
    this.$store.commit(`media/${Mutations.FORM_DIALOG_MEDIA}`, v, { root: true });
  }

  get formDialogMedia(): boolean {
    return this.$store.getters[`media/${Getters.FORM_DIALOG_MEDIA}`];
  }

  get nameRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  close() {
    this.formDialogMedia = false;
  }

  async closeItemDialog() {
    if (this.site) {
      const mediaId = this.mMedia?._id;
      const medias = await mediasService.getMedias(this.site.customerId);
      this.$store.commit(`media/${Mutations.SET_MEDIAS}`, medias, { root: true });
      this.mediaItemDialog = false;
      const foundMedia = medias.find(m => m._id === mediaId);
      if (foundMedia) {
        this.mMedia = _.cloneDeep(foundMedia);
      } else {
        this.clear();
      }
    }
  }

  clear() {
    this.mMedia = {};
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
  }

  async submit(closeAfter = false) {
    this.$store.commit(`core/${Mutations.START_LOADING}`, null, { root: true });
    const media: Media = {
      ...(this.mMedia as Media)
    };

    if (!this.site) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    const { customerId } = this.site;

    Promise.resolve()
      .then(async () => {
        if (this.edition && media._id)
          return mediasService.updateMedia(media._id, media).then(
            () => {
              return "Média modifié avec succès";
            },
            error => {
              this.snack(
                `Erreur lors de la modification du média. (code ${error.response?.status})`,
                SnackType.ERROR,
                5000
              );
            }
          );
        return mediasService.createMedia({ ...media, items: [], customerIds: [customerId] }).then(
          () => {
            return "Média ajouté avec succès";
          },
          error => {
            this.snack(
              `Erreur lors de l'ajout du média. (code ${error.response?.status})`,
              SnackType.ERROR,
              5000
            );
          }
        );
      })
      .finally(() => {
        this.$store.dispatch(`media/${Actions.SET_MEDIAS}`, customerId, { root: true });
        if (closeAfter) this.close();
        else this.clear();
      })
      .then(async okMessage => {
        if (okMessage) this.snack(okMessage);
      })
      .finally(() => {
        this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, null, { root: true });
      });
  }

  async createMediaItem() {
    const media: Media = {
      ...(this.mMedia as Media)
    };

    if (!this.site) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    const { customerId } = this.site;
    if (!this.edition) {
      const createdMedia: Media = await mediasService.createMedia({
        ...media,
        items: [],
        customerIds: [customerId]
      });
      this.mMedia = _.cloneDeep(createdMedia);
    }
    this.currentItem = {
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      images: [],
      name: ""
    };
    this.mediaItemDialog = true;
  }

  editMediaItem(mediaItem: MediaItem) {
    this.currentItem = mediaItem;
    this.mediaItemDialog = true;
  }

  formatDate(date: string) {
    return new Date(date).toLocaleDateString("fr-fr", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });
  }
}
