



























import Vue from "vue";
import Component from "vue-class-component";
import { Getters } from "../store/getters";
import Changelog from "../components/Changelog.vue";

@Component({
  components: { Changelog }
})
export default class Home extends Vue {
  get selected(): boolean {
    return this.$store.getters[`core/${Getters.SELECTED}`];
  }

  get hasSites(): boolean {
    return this.$store.getters[`core/${Getters.HAS_SITES}`];
  }

  get isOauth(): boolean {
    return this.$store.getters[`core/${Getters.IS_OAUTH}`];
  }
}
