





























































































































































import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Slot } from "../model/Slot";

@Component
export default class BookingConfigurationSlots extends Base {
  @Prop() slots!: Slot[];

  hours = Array.from(Array(24).keys()).map(value =>
    value < 10 ? value.toString(10).padStart(2, "0") : value.toString()
  );

  minutes = Array.from(Array(60).keys()).map(value =>
    value < 10 ? value.toString(10).padStart(2, "0") : value.toString()
  );

  deleteConfirmation = false;

  getHourSlot(time: string) {
    return time.split(":")[0];
  }

  getMinuteSlot(time: string) {
    return time.split(":")[1];
  }

  findSlot(slot: Slot): number {
    return this.slots.findIndex(x => x.startTime === slot.startTime && x.endTime === slot.endTime);
  }

  setStatut(value: boolean, slot: Slot) {
    this.slots[this.findSlot(slot)].disabled = !value;
  }

  setStartTime(value: string, slot: Slot, isHour: boolean) {
    this.slots[this.findSlot(slot)].startTime = isHour
      ? `${value}:${this.getMinuteSlot(slot.startTime) || "00"}`
      : `${this.getHourSlot(slot.startTime) || "00"}:${value}`;
  }

  setEndTime(value: string, slot: Slot, isHour: boolean) {
    this.slots[this.findSlot(slot)].endTime = isHour
      ? `${value}:${this.getMinuteSlot(slot.endTime) || "00"}`
      : `${this.getHourSlot(slot.endTime) || "00"}:${value}`;
  }

  startTimeErrors(slot: Slot) {
    if (
      this.slots.some(
        slotItem => slotItem.startTime === slot.startTime && slotItem.endTime !== slot.endTime
      )
    )
      return "Un créneau existe avec cette heure de début";

    return "";
  }

  endTimeErrors(slot: Slot) {
    if (slot.startTime > slot.endTime)
      return "L'heure de début doit être inférieure a l'heure de fin";
    return "";
  }

  seatsErrors(value: number, isThreshold: boolean) {
    if (isThreshold && !value) {
      return "Un nombre de cap par réservation doit être saisie";
    }
    if (!isThreshold && !value) {
      return "Un nombre de place doit être saisie";
    }
    return "";
  }

  allSlotsValid() {
    return !this.slots.every(
      slot =>
        slot.startTime !== "" &&
        slot.endTime !== "" &&
        slot.startTime <= slot.endTime &&
        !this.slots.some(
          slotItem => slotItem.startTime === slot.startTime && slotItem.endTime !== slot.endTime
        ) &&
        slot.seats !== 0 &&
        slot.seatsThreshold !== 0
    );
  }
}
