



















import Component from "vue-class-component";
import { Actions } from "@/shared/core/store/actions";
import { SnackType } from "../../core/events";
import { Mutations } from "../../core/store/mutations";
import { productsService } from "../services/products.service";

import Base from "../../core/components/Base.vue";

@Component
export default class DbProductDelete extends Base {
  deleteConfirmation = false;

  async deleteProduct() {
    const dbProductId = this.dbProduct?._id;

    if (!dbProductId) return;
    this.startLoading();
    await productsService
      .delete(dbProductId)
      .then(() => {
        this.snack("Produit supprimé avec succès");
        this.$store.dispatch(`dbProduct/${Actions.SET_DB_PRODUCTS}`, null, { root: true });
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$store.commit(`dbProduct/${Mutations.FORM_DIALOG_DB}`, false, { root: true });
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
