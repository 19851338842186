





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getters } from "../../core/store/getters";
import { Mutations } from "../../core/store/mutations";
import { DATE_CONFIG } from "./menus.constants";

@Component
export default class DateSelectorField extends Vue {
  menu = false;

  @Prop({ type: Array, default: () => [] })
  rules!: unknown[];

  get formattedValue(): string {
    if (this.value) return this.value.toLocaleString("fr-FR", DATE_CONFIG);
    return "";
  }

  get valueForDatepicker(): string {
    if (this.value) return this.value.toISOString().substring(0, 10);
    return "";
  }

  dateInput(dateStr: string) {
    this.menu = false;
    const date = new Date(dateStr);
    this.$store.commit(`menu/${Mutations.SELECT_DATE}`, date, { root: true });
  }

  get value(): Date | undefined {
    return this.$store.getters[`menu/${Getters.DATE}`];
  }
}
