


























































































































import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Getters } from "../../core/store/getters";
import { Mutations } from "../../core/store/mutations";
import {
  ArrayLengthRule,
  NotEmptyRule,
  ValidationRule
} from "../../core/validation/validation.rules";
import { Service } from "../model/Service";

import Base from "../../core/components/Base.vue";
import { SnackType } from "../../core/events";
import { Menu } from "../model/Menu";
import { Product } from "../model/Product";
import { menusService } from "../services/menus.service";
import DateSelectorField from "./DateSelectorField.vue";
import { DATE_CONFIG } from "./menus.constants";
import ProductDelete from "./ProductDelete.vue";
import ProductSearchField from "./ProductSearchField.vue";

@Component({
  components: {
    DateSelectorField,
    ProductSearchField,
    ProductDelete
  }
})
export default class MenuFormDialogMove extends Base {
  menu = false;

  valid = false;

  selectedService: Service | undefined = undefined;

  products: Product[] = [];

  selectedDate: Date | null = null;

  selectedProducts: Product[] = [];

  @Watch("formDialog")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      this.products = this.currentMenu ? this.currentMenu.products : [];
      this.selectedDate = this.$store.getters[`menu/${Getters.DATE}`];
      this.selectedService = this.$store.getters[`menu/${Getters.SERVICE}`];
    }
  }

  async submit() {
    if (!this.site || !this.selectedDate || !this.selectedService || !this.currentMenu) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    const { site, selectedDate, selectedService, currentMenu } = this;

    Promise.resolve().then(() => {
      return menusService
        .moveProducts(
          currentMenu._id || "",
          selectedDate.toISOString(),
          selectedService.code,
          site.customerId,
          this.selectedProducts.map(product => product._id || "")
        )
        .then(() => {
          return "Produit/s modifié/s avec succès";
        })
        .catch(error => {
          this.snack(
            `Erreur lors de la mise à jour du menu (code ${error.response?.status})`,
            SnackType.ERROR,
            5000
          );
          throw error;
        })
        .finally(() => {
          this.close();
        })
        .then(async okMessage => {
          this.$store.commit(
            `menu/${Mutations.SET_MENUS}`,
            await menusService.getMenus(site.customerId),
            { root: true }
          );
          this.$store.commit(`menu/${Mutations.SELECT_DATE}`, this.selectedDate, { root: true });
          this.$store.commit(`menu/${Mutations.SELECT_SERVICE}`, this.selectedService, {
            root: true
          });
          const newCurrentMenu = (this.$store.getters[`menu/${Getters.MENUS}`] as Menu[]).find(
            (menu: Menu) =>
              menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
          );
          if (newCurrentMenu && newCurrentMenu._id) {
            const updatedCurrentMenu = await menusService
              .getCurrentMenu(newCurrentMenu._id)
              .catch(e => {
                this.snack(
                  `Erreur lors de la mise à jour du menu. (${e.response?.status}) Actualisez la page.`,
                  SnackType.ERROR,
                  5000
                );
              });
            this.$store.commit(`menu/${Mutations.SET_CURRENT_MENU}`, updatedCurrentMenu, {
              root: true
            });
          }
          if (okMessage) this.snack(okMessage);
        })
        .finally(() => {
          this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, { root: true });
        });
    });
  }

  close() {
    this.selectedProducts = [];
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
    this.formDialog = false;
  }

  selectService(newService: Service | undefined) {
    this.selectedService = newService;
  }

  dateInput(dateStr: string) {
    this.menu = false;
    this.selectedDate = new Date(dateStr);
  }

  set formDialog(v: boolean) {
    this.$store.commit(`menu/${Mutations.FORM_DIALOG_MOVE}`, v, { root: true });
  }

  get formDialog(): boolean {
    return this.$store.getters[`menu/${Getters.FORM_DIALOG_MOVE}`];
  }

  get formattedValue(): string {
    if (this.value) return this.value.toLocaleString("fr-FR", DATE_CONFIG);
    return "";
  }

  get valueForDatepicker(): string {
    if (this.value) return this.value.toISOString().substring(0, 10);
    return "";
  }

  get value(): Date | null {
    return this.selectedDate;
  }

  get serviceRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get productsRule(): ValidationRule[] {
    return [ArrayLengthRule];
  }

  set isAllProducts(event: boolean) {
    this.selectedProducts = event ? this.products : [];
  }

  get isAllProducts() {
    return this.selectedProducts.length === this.products.length;
  }
}
