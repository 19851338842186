



































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Mutations } from "../../core/store/mutations";
import { Product } from "../model/Product";
import { menusService } from "../services/menus.service";
import { ALLERGENS, PRODUCT_NUTRISCORE } from "./menus.constants";

@Component
export default class MenuTable extends Base {
  @Prop() readonly products!: Product[];

  hidden = true;

  curloading = false;

  search = "";

  types: [string, string][] = [];

  allergens = Object.entries(ALLERGENS);

  origins: [string, string][] = [];

  nutriscores = Object.entries(PRODUCT_NUTRISCORE);

  selectedTypes: string[] = [];

  headers = [
    { text: "Type", sortable: true, value: "type" },
    { text: "Zone de distribution", sortable: false, value: "zone" },
    { text: "Nom", sortable: true, value: "name" },
    { text: "Nutriscore", sortable: true, value: "nutriscore" },
    { text: "Prix", sortable: true, value: "price" },
    { text: "Allergènes", sortable: true, value: "allergens" },
    { text: "Certifications", sortable: true, value: "origins" },
    { text: "Accompagnement", sortable: false, value: "sides" },
    { text: "Actions", sortable: false, value: "actions" }
  ];

  exportProductsDialog = false;

  dates: string[] = [];

  menu = false;

  async mounted() {
    setTimeout(() => {
      this.hidden = false;
    }, 300);

    const menuConstants = await import(
      `../../../customers/${this.customer}/${this.customer}.constants`
    );
    this.origins = Object.entries(menuConstants.ORIGINS);
    this.types = Object.entries(menuConstants.PRODUCT_TYPES_NAMES);
  }

  @Watch("dates")
  formatInputDate() {
    if (this.dates.length === 2) {
      const [startYear, startMonth, startDay] = this.dates[0].split("-");
      const startDate = `${startDay.padStart(2, "0")}/${startMonth.padStart(2, "0")}/${startYear}`;
      const [year, month, day] = this.dates[1].split("-");
      const endDate = `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      return `${startDate} au ${endDate}`;
    }
    return null;
  }

  getTypeName(enumType: string): string {
    const type = this.types.filter(item => item[0] === enumType)[0];
    return type ? type[1] : "";
  }

  getNutriscore(number: string): string {
    if (number) return this.nutriscores.filter(item => item[0] === number.toString())[0][1];
    return "";
  }

  getAllergen(enumAllergen: string): string {
    const allergen = this.allergens.filter(item => item[0] === enumAllergen)[0];
    return allergen ? allergen[1] : "";
  }

  getOrigin(enumOrigin: string): string {
    const origin = this.origins.filter(item => item[0] === enumOrigin)[0];
    return origin ? origin[1] : "";
  }

  getZone(zoneCode: string): string {
    const zone = this.zones.filter(item => item.code === zoneCode)[0];
    return zone ? zone.name : "";
  }

  filteredProducts() {
    let { products } = this;
    if (this.search && this.search.length > 0) {
      products = products.filter(product => product.name.includes(this.search));
    }
    if (this.selectedTypes.length > 0)
      products = products.filter(product =>
        this.selectedTypes.some(selectedType => selectedType === product.type)
      );
    return products;
  }

  createProduct() {
    this.$store.commit(`menu/${Mutations.SELECT_PRODUCT_ID}`, null, { root: true });
    this.$store.commit(`menu/${Mutations.FORM_DIALOG}`, true, { root: true });
  }

  editProduct(product: Product) {
    this.$store.commit(`menu/${Mutations.SELECT_PRODUCT_ID}`, product._id, { root: true });
    this.$store.commit(`menu/${Mutations.FORM_DIALOG}`, true, { root: true });
  }

  openMoveProductsModal() {
    this.$store.commit(`menu/${Mutations.FORM_DIALOG_MOVE}`, true, { root: true });
  }

  openDuplicateProductsModal() {
    this.$store.commit(`menu/${Mutations.FORM_DIALOG_DUPLICATE}`, true, { root: true });
  }

  exportMenus() {
    if (this.site && this.date) {
      this.curloading = true;
      menusService.exportMenus(this.site.customerId, this.date.toISOString()).then(data => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute(
          "download",
          `Menu_${this.site?.name}_${this.date?.toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        this.curloading = false;
      });
    }
  }

  exportProducts() {
    if (this.site && this.dates) {
      this.curloading = true;
      const from = new Date(this.dates[0]);
      const to = new Date(this.dates[1]);
      to.setHours(23, 59, 59);
      menusService
        .exportProducts(this.site.customerId, from.toISOString(), to.toISOString())
        .then(data => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute(
            "download",
            `Produits_${this.site?.name}_${from.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric"
            })}-${to.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "numeric",
              day: "numeric"
            })}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.exportProductsDialog = false;
          this.dates = [];
        }).finally(() => {
          this.curloading = false;
        });
    }
  }
}
