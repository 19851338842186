




































import Vue from "vue";
import Component from "vue-class-component";
import { usersService } from "../services/users.service";

@Component
export default class Admin extends Vue {
  apikey = "";

  loading = false;

  show = false;

  error = "";

  mounted() {
    if (localStorage.apikey) this.apikey = localStorage.apikey;
    delete localStorage.apikey;
  }

  async validate() {
    this.error = "";
    this.loading = true;
    localStorage.apikey = this.apikey;
    delete localStorage.token;
    usersService
      .getMyUser()
      .then(
        () => {
          this.home();
        },
        error => {
          this.apikey = "";
          delete localStorage.apikey;
          this.error = `La clé ne semble pas valide (${error.response?.status})`;
        }
      )
      .finally(() => {
        (this.$refs.input as HTMLElement).focus();
        this.loading = false;
      });
  }

  reset() {
    delete localStorage.apikey;
    this.apikey = "";
    this.home();
  }

  home() {
    this.$router.push("/");
  }
}
