
















































































































































































































import Component from "vue-class-component";
import _ from "lodash";
import { Watch } from "vue-property-decorator";
import { Product } from "../model/Product";
import {
  IsValidPriceRule,
  LengthRule,
  NoCurrencyRule,
  NotEmptyRule,
  ValidationRule
} from "../../core/validation/validation.rules";
import { menusService } from "../services/menus.service";
import { Getters } from "../../core/store/getters";
import { Service } from "../model/Service";
import { SnackType } from "../../core/events";
import { SearchProduct } from "../model/SearchProduct";
import { productsService } from "../../db-products/services/products.service";
import { Mutations } from "../../core/store/mutations";
import { Menu } from "../model/Menu";

import DateSelectorField from "./DateSelectorField.vue";
import { ALLERGENS, PRODUCT_NUTRISCORE } from "./menus.constants";
import Base from "../../core/components/Base.vue";
import ProductDelete from "./ProductDelete.vue";
import ProductSearchField from "./ProductSearchField.vue";

@Component({
  components: {
    DateSelectorField,
    ProductSearchField,
    ProductDelete
  }
})
export default class MenuFormDialog extends Base {
  mProduct: Partial<Product> = {};

  valid = false;

  origins: [string, string][] = [];

  productTypesNames: [string, string][] = [];

  @Watch("formDialog")
  opening(newValue: boolean, oldValue: boolean): void {
    if (newValue && !oldValue) {
      // Ouverture
      if (this.product) {
        this.mProduct = _.cloneDeep(this.product);
      } else {
        this.clear();
        this.mProduct.zoneCode = this.zone ? this.zone.code : undefined;
      }
    }
  }

  get edition() {
    return Boolean(this.mProduct._id);
  }

  async mounted() {
    const menuConstants = await import(
      `../../../customers/${this.customer}/${this.customer}.constants`
    );
    this.origins = menuConstants.ORIGINS;
    this.productTypesNames = menuConstants.PRODUCT_TYPES_NAMES;
  }

  async submit(closeAfter = false) {
    if (!this.site || !this.date || !this.service) {
      this.snack("Les conditions de soumissions ne sont pas valides", SnackType.ERROR, 5000);
      return;
    }
    this.$store.commit(`core/${Mutations.START_LOADING}`, { root: true });
    this.$store.commit(
      `menu/${Mutations.SET_ZONE}`,
      this.zones.filter(zone => zone.code === this.mProduct.zoneCode)[0],
      { root: true }
    );
    const { site, date, service } = this;
    const product: Product = {
      ...(this.mProduct as Product),
      sides: this.mProduct.type === "ACC" ? "" : this.mProduct.sides,
      sidesEn: this.mProduct.type === "ACC" ? "" : this.mProduct.sidesEn,
      price: this.mProduct.price || 0,
      nutriscore: this.mProduct.nutriscore || 0
    };
    const foundMenu = (this.$store.getters[`menu/${Getters.MENUS}`] as Menu[]).find(
      (menu: Menu) =>
        menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
    );
    Promise.resolve()
      .then(async () => {
        // Création d'un menu s'il n'existe pas
        if (!foundMenu) {
          return menusService.createMenu({
            date: date.toISOString(),
            serviceCode: service?.code,
            customerId: site.customerId,
            products: []
          });
        }
        return foundMenu;
      })
      .then(async menu => {
        const menuId = menu._id || "#";
        if (this.edition && product._id) {
          // Édition
          return menusService
            .updateProductInMenu(menuId, product._id, product)
            .then(() => {
              return "Produit modifié avec succès";
            })
            .catch(error => {
              this.snack(
                `Erreur lors de la mise à jour du menu (code ${error.response?.status})`,
                SnackType.ERROR,
                5000
              );
              throw error;
            });
        }
        // Ajout
        return menusService.addProductToMenu(menuId, product).then(
          () => {
            return "Produit ajouté avec succès";
          },
          error => {
            this.snack(
              `Erreur lors de l'ajout du produit. (code ${error.response?.status})`,
              SnackType.ERROR,
              5000
            );
          }
        );
      })
      .finally(() => {
        if (closeAfter) this.close();
        else this.clear();
      })
      .then(async okMessage => {
        this.$store.commit(
          `menu/${Mutations.SET_MENUS}`,
          await menusService.getMenus(site.customerId),
          { root: true }
        );
        const currentMenu = (this.$store.getters[`menu/${Getters.MENUS}`] as Menu[]).find(
          (menu: Menu) =>
            menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
        );
        if (currentMenu && currentMenu._id) {
          const updatedCurrentMenu = await menusService.getCurrentMenu(currentMenu._id).catch(e => {
            this.snack(
              `Erreur lors de la mise à jour du menu. (${e.response?.status}) Actualisez la page.`,
              SnackType.ERROR,
              5000
            );
          });
          this.$store.commit(`menu/${Mutations.SET_CURRENT_MENU}`, updatedCurrentMenu, {
            root: true
          });
        }
        if (okMessage) this.snack(okMessage);
      })
      .finally(() => {
        this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, { root: true });
      });
  }

  close() {
    this.formDialog = false;
  }

  async nameAndSearchInput(input: string | SearchProduct) {
    if (_.isString(input)) {
      // if (this.mProduct.dbSourceId && this.mProduct.name !== input)
      //   this.mProduct.dbSourceId = undefined;
      this.mProduct.name = input;
    } else {
      // const zoneCodeBackup = this.mProduct.zoneCode;
      // this.clear();
      this.mProduct = productsService.mergeSearchProduct(this.mProduct, input);
      // this.mProduct.zoneCode = zoneCodeBackup;
      if (this.site && this.mProduct.dbSourceId) {
        this.startLoading();
        await productsService.usage(this.site.customerId, this.mProduct.dbSourceId).finally(() => {
          this.releaseLoading();
        });
      }
    }
  }

  clear() {
    this.mProduct = {};
    // this.edition = false;
    // if (this.$refs.nameInput) (this.$refs.nameInput as HTMLElement).focus();
    if (this.$refs.form) {
      const form = this.$refs.form as HTMLFormElement;
      form.resetValidation();
    }
  }

  selectService(newService: Service | undefined) {
    this.$store.commit(`menu/${Mutations.SELECT_SERVICE}`, newService || null, { root: true });
  }

  get nameRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get serviceRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get priceRules(): ValidationRule[] {
    return [NoCurrencyRule, IsValidPriceRule];
  }

  get typeRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get zoneRules(): ValidationRule[] {
    return [NotEmptyRule];
  }

  get originsRules(): ValidationRule[] {
    return [];
  }

  get sidesRules(): ValidationRule[] {
    return [LengthRule(0, 75)];
  }

  get productTypeItems(): unknown[] {
    return Object.entries(this.productTypesNames).map(([typeCode, typeName]) => {
      return { value: typeCode, text: typeName };
    });
  }

  get allergensItems(): unknown[] {
    return Object.entries(ALLERGENS).map(([code, name]) => {
      return { value: code, text: name };
    });
  }

  get originsItems(): unknown[] {
    return Object.entries(this.origins).map(([code, name]) => {
      return { value: code, text: name };
    });
  }

  get nutriscoreItems(): unknown[] {
    return Object.entries(PRODUCT_NUTRISCORE).map(([code, name]) => {
      return { value: parseInt(code, 10), text: name };
    });
  }

  set formDialog(v: boolean) {
    this.$store.commit(`menu/${Mutations.FORM_DIALOG}`, v, { root: true });
  }

  get formDialog(): boolean {
    return this.$store.getters[`menu/${Getters.FORM_DIALOG}`];
  }
}
