














































































































































import Component from "vue-class-component";
import Base from "../components/Base.vue";
import { SnackType } from "../events";
import { usersService } from "../services/users.service";
import {
  LengthRule,
  NotEmptyRule,
  NotValidEmailRule,
  ValidationRule
} from "../validation/validation.rules";

@Component
export default class Login extends Base {
  valid = false;

  form = {
    email: localStorage.email || "",
    password: "",
    rememberMe: !!localStorage.email
  };

  isVisiblePassword = false;

  resetPasswordMode = false;

  hasLogoApp = false;

  async mounted() {
    this.hasLogoApp = await this.customerHasLogoApp();
  }

  get notEmailRules(): ValidationRule[] {
    return [NotEmptyRule, NotValidEmailRule];
  }

  get notPasswordRules(): ValidationRule[] {
    return [NotEmptyRule, LengthRule(5, 30)];
  }

  changeInputTypePassword() {
    const x = document.getElementById("myPassword") as HTMLInputElement;
    if (x) {
      x.type = x.type === "password" ? (x.type = "text") : (x.type = "password");
      this.isVisiblePassword = !this.isVisiblePassword;
    }
  }

  onResetPassword() {
    usersService
      .requestResetPassword({ email: this.form.email.toLowerCase() })
      .then(() => {
        this.snack(`La demande a été envoyée`, SnackType.SUCCESS, 5000);
      })
      .catch(e => {
        if (e.response?.status === 401) {
          this.snack(`Les identifiants sont incorrects`, SnackType.ERROR, 5000);
        } else {
          this.snack(
            `Erreur lors de la connexion. (${e.response?.status}) Actualisez la page.`,
            SnackType.ERROR,
            5000
          );
        }
      });
  }

  login() {
    usersService
      .login({ email: this.form.email.toLowerCase(), password: this.form.password })
      .then(response => {
        localStorage.accessToken = response.accessToken;
        localStorage.refreshToken = response.refreshToken;
        localStorage.email = this.form.rememberMe ? this.form.email.toLowerCase() : "";
        this.$router.push({ name: "Home.default" });
      })
      .catch(e => {
        if (e.response?.status === 401) {
          this.snack(`Les identifiants sont incorrects`, SnackType.ERROR, 5000);
        } else {
          this.snack(
            `Erreur lors de la connexion. (${e.response?.status}) Actualisez la page.`,
            SnackType.ERROR,
            5000
          );
        }
      });
  }
}
