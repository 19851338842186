





















































































import { Actions } from "@/shared/core/store/actions";
import { Getters } from "@/shared/core/store/getters";
import { Mutations } from "@/shared/core/store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';
import { Menu } from "../model/Menu";
import { Service } from "../model/Service";
import { Zone } from "../model/Zone";
import { DATE_CONFIG } from "./menus.constants";

@Component
export default class ServiceSelector extends Vue {
  modal = false;

  selectedDate: string | null = null;

  selectedDateModal: string | null = null;

  mounted() {
    if (this.date) {
      this.selectedDate = new Date(this.date).toISOString().substring(0, 10);
    }
  }

  // @Watch("selectedDate")
  // selectDate() {
  //   console.log("selectedDate", this.selectedDate);
  // }

  @Watch("selectedDateModal")
  selectDateModal() {
    if (this.selectedDateModal) {
      this.date = new Date(this.selectedDateModal);
    }
  }

  get isAdmin(): boolean {
    return this.$store.getters[`core/${Getters.IS_ADMIN}`];
  }

  get computedDateFormatted() {
    if (this.selectedDate) return this.formatDate(this.selectedDate);
    return '';
  }

  formatDate(date: string) {
    if (!date) return null
    const [year, month, day] = date.split('-')
    return `${day}/${month}/${year}`
  }

  parseDate(date: string) {
    const [day, month, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  allowedDates(val: string) {
    return this.datesToString.includes(val);
  }

  get datesToString() {
    return this.dates.map((date: Date) => {
      return date.toISOString().substr(0, 10);
    });
  }

  get dates(): Date[] {
    const allDates: Date[] = this.$store.getters[`menu/${Getters.MENUS_DATES}`];
    return this.isAdmin
      ? allDates
      : allDates.filter((date: Date) => {
          const currentDate = new Date();
          const daysAgo = new Date();
          let removeDays = 0;
          switch (currentDate.getDay()) {
            case 1:
              removeDays = 4;
              break;
            case 2:
              removeDays = 3;
              break;
            default:
              removeDays = 2;
              break;
          }
          daysAgo.setDate(currentDate.getDate() - removeDays);
          return date >= daysAgo;
        });
  }

  get services(): Date[] {
    return this.$store.getters[`menu/${Getters.SERVICES}`];
  }

  set date(nDate: Date | null) {
    console.log("date", nDate, typeof nDate);
    this.$store.commit(`menu/${Mutations.SELECT_DATE}`, nDate, { root: true });
    this.$store.commit(`menu/${Mutations.SET_ZONE}`, null, { root: true });
    if (this.services.length === 1) {
      this.$store.commit(`menu/${Mutations.SELECT_SERVICE}`, this.services[0], { root: true });
      this.$store.commit(`menu/${Mutations.SET_ZONE}`, null, { root: true });
    }
    this.updateCurrentMenu();
  }

  get date(): Date | null {
    return this.$store.state.menu.date;
  }

  set service(service: Service | null) {
    this.$store.commit(`menu/${Mutations.SELECT_SERVICE}`, service, { root: true });
    this.$store.commit(`menu/${Mutations.SET_ZONE}`, null, { root: true });
    this.updateCurrentMenu();
  }

  get service(): Service | null {
    return this.$store.state.menu.service;
  }

  // get zones(): Zone[] {
  //   return this.$store.getters[`menu/${Getters.ZONES}`];
  // }

  // set zone(zone: Zone | null) {
  //   this.$store.commit(`menu/${Mutations.SET_ZONE}`, zone, { root: true });
  // }

  // get zone(): Zone | null {
  //   return this.$store.getters[`menu/${Getters.ZONE}`];
  // }

  makeServiceText(service: Service): string {
    return service.name;
  }

  makeDateText(date: Date): string {
    return date.toLocaleString("fr", DATE_CONFIG);
  }

  makeZoneText(zone: Zone): string {
    return zone.name;
  }

  updateCurrentMenu() {
    if (this.service && this.date) {
      const foundMenu = (this.$store.getters[`menu/${Getters.MENUS}`] as Menu[]).find(
        (menu: Menu) =>
          menu.date === this.date?.toISOString() && menu.serviceCode === this.service?.code
      );
      if (foundMenu)
        this.$store.dispatch(`menu/${Actions.SET_CURRENT_MENU}`, foundMenu._id, { root: true });
    }
  }
}
