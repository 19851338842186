





































































































import Component from "vue-class-component";
import Base from "../components/Base.vue";
import { SnackType } from "../events";
import { usersService } from "../services/users.service";
import { ValidationRule, NotEmptyRule, LengthRule } from "../validation/validation.rules";

@Component
export default class ResetPassword extends Base {
  valid = false;

  form = {
    email: "",
    password: "",
    checkPassword: ""
  };

  isSamePassword = false;

  hasLogoApp = false;

  parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  get notPasswordRules(): ValidationRule[] {
    return [NotEmptyRule, LengthRule(5, 30)];
  }

  async mounted() {
    this.hasLogoApp = await this.customerHasLogoApp();
    this.verifyToken();
    this.form.email = this.parseJwt(this.$route.params.token).email;
  }

  verifyToken() {
    usersService.verifyToken(this.$route.params.token).catch(e => {
      if (e.response?.status === 401 || e.response?.status === 406) {
        this.snack(
          `La demande a expirée. Veuillez créer une nouvelle demande.`,
          SnackType.ERROR,
          5000
        );
        this.$router.push({ name: "Login" });
      } else {
        this.snack(
          `Erreur lors de la connexion. (${e.response?.status}) Actualisez la page.`,
          SnackType.ERROR,
          5000
        );
      }
    });
  }

  resetPassword() {
    usersService
      .resetPassword({
        email: this.form.email,
        password: this.form.password,
        token: this.$route.params.token
      })
      .then(response => {
        localStorage.token = response.token;
        this.snack(`Mot de passe modifié avec succès`, SnackType.SUCCESS, 5000);
        this.$router.push({ name: "Home.default" });
      })
      .catch(e => {
        if (e.response?.status === 401 || e.response?.status === 406) {
          this.snack(
            `La demande a expirée. Veuillez créer une nouvelle demande.`,
            SnackType.ERROR,
            5000
          );
        } else {
          this.snack(
            `Erreur lors de la connexion. (${e.response?.status}) Actualisez la page.`,
            SnackType.ERROR,
            5000
          );
        }
      });
  }
}
