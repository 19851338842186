




import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Base from "../../core/components/Base.vue";
import { Product } from "../model/Product";
import MenuTable from "./MenuTable.vue";

@Component({
  components: {
    MenuTable
  }
})
export default class MenuService extends Base {
  products: Product[] = [];

  @Watch("$store.state.menu.currentMenu")
  getNewProducts(): void {
    this.products = this.currentMenu ? this.currentMenu.products : [];
  }

  mounted() {
    this.getNewProducts();
  }
}
