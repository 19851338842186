













import Base from "@/shared/core/components/Base.vue";
import { User } from "@/shared/core/model/User";
import { Actions } from "@/shared/core/store/actions";
import Component from "vue-class-component";
import CoreAppBar from "../components/CoreAppBar.vue";
import CoreNavigationDrawer from "../components/CoreNavigationDrawer.vue";
import { usersService } from "../services/users.service";
import { Mutations } from "../store/mutations";
import { siteService } from "../services/site.service";

@Component({
  components: { CoreAppBar, CoreNavigationDrawer }
})
export default class Index extends Base {
  async mounted(): Promise<void> {
    // Chargement des données de base
    this.startLoading();
    usersService
      .getMyUser()
      .then(
        async (user: User) => {
          this.$store.commit(`core/${Mutations.SET_USER}`, user, { root: true });
        },
        error => {
          throw error;
        }
      )
      .then(async () => {
        const sitesList = await siteService.getSitesList();
        this.$store.dispatch(`core/${Actions.SET_LIST}`, sitesList, { root: true });
      })
      .then(async () => {
        this.releaseLoading();
      });
  }
}
