



















import Component from "vue-class-component";
import { SnackType } from "../../core/events";
import { Mutations } from "../../core/store/mutations";
import { menusService } from "../services/menus.service";
import Base from "../../core/components/Base.vue";

@Component
export default class ProductDelete extends Base {
  deleteConfirmation = false;

  async deleteProduct() {
    const menuId = this.currentMenu?._id;
    const productId = this.product?._id;
    const siteCustomerId = this.site?.customerId;
    const { currentMenu } = this;
    if (!productId || !menuId || !siteCustomerId || !currentMenu) return;

    this.startLoading();
    await menusService
      .removeProductFromMenu(menuId, productId)
      .then(async () => {
        return currentMenu.products.length > 1
          ? menusService.getCurrentMenu(menuId)
          : Promise.resolve(null);
      })
      .then(newCurrentMenu => {
        this.$store.commit(`menu/${Mutations.SET_CURRENT_MENU}`, newCurrentMenu, { root: true });
      })
      .then(() => {
        this.snack("Produit supprimé avec succès");
      })
      .catch(error => {
        this.snack(
          `Une erreur est survenue pendant la suppression. (${error.response?.status})`,
          SnackType.ERROR,
          8000
        );
      })
      .finally(() => {
        this.$store.commit(`menu/${Mutations.FORM_DIALOG}`, false, { root: true });
        this.releaseLoading();
        this.deleteConfirmation = false;
      });
  }
}
