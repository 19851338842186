var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"d-flex flex-column fill-height mb-4"},[_c('v-card-title',{staticClass:"title-communication"},[_c('v-btn',{staticClass:"mr-3",attrs:{"to":"/","exact":"","small":"","text":"","icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Produits "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-sm-center flex-column flex-sm-row search-mobile"},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.types,"item-text":"[1]","item-value":"[0]","label":"Type","multiple":"","hide-details":"auto","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item[1]))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTypes.length - 1)+" autres) ")]):_vm._e()]}}]),model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",attrs:{"append-icon":"mdi-magnify","label":"Rechercher...","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden),expression:"!hidden"}],staticClass:"mt-2 mt-sm-0",attrs:{"loading":_vm.curloading,"color":"primary","elevation":"2","dark":""},on:{"click":_vm.createProduct}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Ajouter un produit")])])],1)],1)],1),_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts(),"sort-by":"type","multi-sort":"","no-data-text":"Aucun produit d'enregistrés","no-results-text":"Aucun produit trouvés","header-props":{ sortByText: 'Trié par' },"footer-props":{
                itemsPerPageText: 'Produit par page',
                itemsPerPageAllText: 'Tous',
                pageText: '{0}-{1} de {2}'
              }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":"","rounded":""},on:{"click":_vm.openMoveProductsModal}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-content-cut")])],1)]}}])},[_c('span',[_vm._v("Déplacer un/des produit/s")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":"","rounded":""},on:{"click":_vm.openDuplicateProductsModal}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-content-duplicate")])],1)]}}])},[_c('span',[_vm._v("Dupliquer un/des menu/s")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:" d-flex align-center",attrs:{"text":"","rounded":""},on:{"click":_vm.exportMenus}},on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-export-variant")])],1)]}}])},[_c('span',[_vm._v("Exporter les menus du jour")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-dialog',_vm._g({attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" d-flex align-center",attrs:{"text":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-file-export")])],1)]}}],null,true),model:{value:(_vm.exportProductsDialog),callback:function ($$v) {_vm.exportProductsDialog=$$v},expression:"exportProductsDialog"}},on),[_c('v-card',[_c('v-card-title',{staticClass:"body-2 pl-2"},[_vm._v(" Exporter les produits ")]),_c('v-card-text',{staticClass:"pa-4 pt-0"},[_c('p',[_vm._v("Veuillez choisir une période d'export.")]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatInputDate(),"append-icon":"mdi-calendar","label":"Dates","readonly":"","single-line":"","hide-details":"","clearable":"","full-width":""},on:{"click:clear":function($event){_vm.dates = []}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","locale":"fr-FR","first-day-of-week":"1","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-6",attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.exportProductsDialog = false}}},[_vm._v(" Fermer ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.dates.length !== 2},on:{"click":_vm.exportProducts}},[_vm._v(" Exporter ")])],1)],1)],1)]}}])},[_c('span',[_vm._v("Exporter les produits")])])],1)]},proxy:true},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeName(item.type))+" ")]}},{key:"item.nutriscore",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNutriscore(item.nutriscore))+" ")]}},{key:"item.price",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.price ? item.price + "€" : "")+" ")]}},{key:"item.allergens",fn:function(ref){
              var item = ref.item;
return _vm._l((item.allergens),function(allergen){return _c('v-chip',{key:allergen,staticClass:"ma-1",attrs:{"small":"","color":""}},[_vm._v(" "+_vm._s(_vm.getAllergen(allergen))+" ")])})}},{key:"item.origins",fn:function(ref){
              var item = ref.item;
return _vm._l((item.origins),function(origin){return _c('v-chip',{key:origin,staticClass:"ma-1",attrs:{"small":"","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.getOrigin(origin))+" ")])})}},{key:"item.zone",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getZone(item.zoneCode))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }