



































import Component from "vue-class-component";
import Base from "../../core/components/Base.vue";
import { Mutations } from "../../core/store/mutations";
import MenuService from "../components/MenuService.vue";
import ProductFormDialog from "../components/ProductFormDialog.vue";
import MenuFormDialogDuplicate from "../components/MenuFormDialogDuplicate.vue";
import ProductFormDialogMove from "../components/ProductFormDialogMove.vue";

import ServiceSelector from "../components/ServiceSelector.vue";

@Component({
  components: {
    ServiceSelector,
    MenuService,
    ProductFormDialog,
    ProductFormDialogMove,
    MenuFormDialogDuplicate
  }
})
export default class Menus extends Base {
  model = 0;

  menuCreationDialog = false;

  createProduct() {
    this.$store.commit(`menu/${Mutations.SELECT_PRODUCT_ID}`, null, { root: true });
    this.$store.commit(`menu/${Mutations.FORM_DIALOG}`, true, { root: true });
  }

  get selected(): boolean {
    return this.$store.getters.selected;
  }
}
