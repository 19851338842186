



















































import Component from "vue-class-component";
import Base from "./Base.vue";

@Component
export default class Changelog extends Base {}
