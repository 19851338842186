var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('DbProductFormDialog'),_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fill-height"},[_c('v-card',{staticClass:"d-flex flex-column fill-height mb-4"},[_c('v-card-title',{staticClass:"title-communication"},[_c('v-btn',{staticClass:"mr-3",attrs:{"to":"/","exact":"","small":"","text":"","icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" Produits "),_c('v-spacer'),_c('div',{staticClass:"d-flex align-sm-center flex-column flex-sm-row search-mobile"},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.types,"item-text":"[1]","item-value":"[0]","label":"Type","multiple":"","hide-details":"auto","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item[1]))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedTypes.length - 1)+" autres) ")]):_vm._e()]}}]),model:{value:(_vm.selectedTypes),callback:function ($$v) {_vm.selectedTypes=$$v},expression:"selectedTypes"}}),_c('v-text-field',{staticClass:"mr-4 mt-5 mt-sm-0",attrs:{"append-icon":"mdi-magnify","label":"Rechercher...","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden),expression:"!hidden"}],staticClass:"mt-2 mt-sm-0",attrs:{"color":"primary","elevation":"0","height":"40px","dark":""},on:{"click":_vm.createProduct}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v("Créer un produit")])])],1)],1)],1),_c('v-card',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-data-table',{staticClass:"pa-3",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts(),"sort-by":"type","multi-sort":"","no-data-text":"Aucun produit d'enregistrés","no-results-text":"Aucun produit trouvés","header-props":{ sortByText: 'Trié par' },"footer-props":{
                itemsPerPageText: 'Produit par page',
                itemsPerPageAllText: 'Tous',
                pageText: '{0}-{1} de {2}'
              }},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeName(item.type))+" ")]}},{key:"item.nutriscore",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNutriscore(item.nutriscore))+" ")]}},{key:"item.price",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.price ? item.price + "€" : "")+" ")]}},{key:"item.allergens",fn:function(ref){
              var item = ref.item;
return _vm._l((item.allergens),function(allergen){return _c('v-chip',{key:allergen,staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getAllergen(allergen))+" ")])})}},{key:"item.origins",fn:function(ref){
              var item = ref.item;
return _vm._l((item.origins),function(origin){return _c('v-chip',{key:origin,staticClass:"ma-1",attrs:{"small":"","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.getOrigin(origin))+" ")])})}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }