
import { Media } from "@/shared/medias/model/Media";
import Vue from "vue";
import Component from "vue-class-component";
import { Article } from "../../articles/model/Article";
import { DbProduct } from "../../db-products/model/DbProduct";
import { Menu } from "../../menus/model/Menu";
import { Product } from "../../menus/model/Product";
import { Service } from "../../menus/model/Service";
import { Zone } from "../../menus/model/Zone";
import { SNACK_EVENT_NAME, SnackEvent, SnackType } from "../events";
import Site from "../model/Site";
import SiteInList from "../model/SiteInList";
import { User } from "../model/User";
import { Getters } from "../store/getters";
import { Mutations } from "../store/mutations";

@Component
export default class Base extends Vue {
  get site(): Site | null {
    return this.$store.getters[`core/${Getters.SITE}`];
  }

  get services(): Service[] {
    return this.$store.getters[`menu/${Getters.SERVICES}`];
  }

  get zones(): Zone[] {
    return this.$store.getters[`menu/${Getters.ZONES}`];
  }

  get user(): User | undefined {
    return this.$store.state.core.user;
  }

  get date(): Date | null {
    return this.$store.state.menu.date;
  }

  get zone(): Zone {
    return this.$store.getters[`menu/${Getters.ZONE}`];
  }

  get currentMenu(): Menu | null {
    return this.$store.getters[`menu/${Getters.CURRENT_MENU}`];
  }

  get product(): Product | null {
    return this.$store.getters[`menu/${Getters.PRODUCT}`];
  }

  get dbProduct(): DbProduct | null {
    return this.$store.getters[`dbProduct/${Getters.DB_PRODUCT}`];
  }

  get media(): Media | null {
    return this.$store.getters[`media/${Getters.MEDIA}`];
  }

  get list(): SiteInList[] {
    return this.$store.state.core.list;
  }

  get service(): Service | null {
    return this.$store.getters[`menu/${Getters.SERVICE}`];
  }

  get loading(): boolean {
    return this.$store.getters[`core/${Getters.LOADING}`];
  }

  get articles(): Article[] {
    return this.$store.getters[`article/${Getters.ARTICLES}`];
  }

  get isAdmin(): boolean {
    return this.$store.getters[`core/${Getters.IS_ADMIN}`];
  }

  get enableI18n(): boolean {
    return this.$store.getters[`core/${Getters.ENABLE_I18N}`];
  }

  get maxWidth(): string {
    if (this.$vuetify.breakpoint.xl) return "55%";
    if (this.$vuetify.breakpoint.lgAndUp) return "65%";
    return "100%";
  }

  get customer(): string {
    return process.env.VUE_APP_CUSTOMER;
  }

  async getCustomerName() {
    return (await import(`../../../customers/${this.customer}/config`)).NAME;
  }

  async customerHasLogoApp() {
    return (await import(`../../../customers/${this.customer}/config`)).HAS_LOGO_APP;
  }

  async userAddDbProduct() {
    return (await import(`../../../customers/${this.customer}/config`)).USER_ADD_DB_PRODUCT;
  }

  enableAddDbProduct() {
    const sites = ["SOL", "LBP", "AQD"];
    return sites.includes(this.site?.customerId || "");
  }

  startLoading() {
    this.$store.commit(`core/${Mutations.START_LOADING}`, null, { root: true });
  }

  releaseLoading() {
    this.$store.commit(`core/${Mutations.RELEASE_LOADING}`, null, { root: true });
  }

  snack(message: string, type = SnackType.SUCCESS, timeout = 3500, multiline = false) {
    this.$root.$emit(SNACK_EVENT_NAME, new SnackEvent(message, type, timeout, multiline));
  }
}
